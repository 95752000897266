import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import GroupIcon from "@mui/icons-material/Group";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatIcon from "@mui/icons-material/Chat";
import EventIcon from "@mui/icons-material/Event";
import GroupMembers from "./GroupMembers";
import DDPSetting from "./DDPSetting";
import GroupChatEx from "./GroupChatEx";
import Settings from "./SettingsGroup";
import Schedule from "./Schedule"; // スケジュールコンポーネントをインポート
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Tooltip,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  FormGroup,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import SurveyIcon from "@mui/icons-material/QuestionAnswer"; // アンケートアイコン
import CloseIcon from "@mui/icons-material/Close"; // ×アイコン
import MovieIcon from "@mui/icons-material/Movie"; // ムービーアイコンをインポート

import SophieMission from "./SophieMission";
import Webinar from "./Webinar";

const Group = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);
  const [user, setUser] = useState(null);
  const [isMissionDrawerOpen, setMissionDrawerOpen] = useState(false);
  const [isSurveyDialogOpen, setSurveyDialogOpen] = useState(false); // アンケートダイアログの状態
  const [isFabVisible, setFabVisible] = useState(true); // Fabの表示状態
  const [surveyAnswers, setSurveyAnswers] = useState({
    chatPresence: "", // チャット有無
    activityNumber: "", // 活動人数
    missions: [], // グループで取り組めたミッション（複数選択）
    zoomUnavailable: {
      days: [], // 全体zoom会の開催曜日（複数選択）
      times: [], // 全体zoom会の開催時間帯（複数選択）
    },
    questionsToMitsurou: "", // みつろうさんに質問をしたいこと（自由記述）
    ddpAchieved: "", // 既にDDPが叶った方は願いの内容を教えてください（yes/no）
    ddpWish: "", // yesの方のみ自由記述
  }); // アンケートの回答
  const [ddpConfirmedCount, setDdpConfirmedCount] = useState(""); // DDP確定人数の状態
  const [isThankYouDialogOpen, setThankYouDialogOpen] = useState(false); // ありがとうダイアログの状態

  const surveyId = "202410Early"; // ここでアンケートIDを設定

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const topOffset = isSmallScreen ? "56px" : "64px"; // スマホとそれ以上のサイズでtopのオフセットを変更

  useEffect(() => {
    const checkSurveyResponse = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser(userData);

          // すでにアンケートに回答しているか確認
          const userSurveyQuery = query(
            collection(db, "users", userId, "fab"),
            where("surveyId", "==", surveyId)
          );
          const userSurveySnap = await getDocs(userSurveyQuery);
          setFabVisible(userSurveySnap.empty); // 回答済みならアンケートアイコンを非表示
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    checkSurveyResponse();
  }, [surveyId]); // アンケートIDが変わるたびに実行

  useEffect(() => {
    const fetchGroup = async () => {
      if (!user) return;

      setLoading(true);
      try {
        if (!user.isAdmin && user.participatingGroup !== id) {
          navigate(`/group/${user.participatingGroup}`);
          return;
        }

        const docRef = doc(db, "groups", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setGroup(docSnap.data());
        } else {
          setError("Group not found");
        }
      } catch (err) {
        setError("Failed to fetch group");
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [id, user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  const handleMissionClick = () => {
    setMissionDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setMissionDrawerOpen(false);
  };

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      // Chatを選択したとき
      setFabVisible(false); // Fabを非表示
    } else {
      if (surveyId && isFabVisible) {
        // Fabがすでに非表示なら再表示しない
        setFabVisible(true); // Fabを表示
      }
    }
  };

  const handleSurveyClick = async () => {
    if (!user) return;

    try {
      // ユーザーがすでにアンケートに回答しているかどうかを確認
      const userSurveyQuery = query(
        collection(db, "users", user.uid, "fab"),
        where("surveyId", "==", surveyId)
      );
      const userSurveySnap = await getDocs(userSurveyQuery);

      if (userSurveySnap.empty) {
        // 回答していない場合のみアンケートダイアログを開く
        setSurveyDialogOpen(true);
      } else {
        // すでに回答済みの場合はFabを非表示にする（任意）
        setFabVisible(false);
        console.log("ユーザーはすでにアンケートに回答済みです");
      }
    } catch (error) {
      console.error("アンケート回答のチェックに失敗しました", error);
    }
  };

  const handleSurveyClose = () => {
    setSurveyDialogOpen(false);
  };

  const handleSurveyAnswerChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (
      name === "missions" ||
      name === "zoomUnavailableDays" ||
      name === "zoomUnavailableTimes"
    ) {
      // 複数選択の処理
      let updatedArray = [];
      if (name === "missions") {
        updatedArray = [...surveyAnswers.missions];
        if (checked) {
          updatedArray.push(value);
        } else {
          updatedArray = updatedArray.filter((item) => item !== value);
        }
        setSurveyAnswers((prev) => ({
          ...prev,
          missions: updatedArray,
        }));

        // DDP作成ポイントがオフになった場合、ddpConfirmedCountをリセット
        if (value === "ddpPoints" && !checked) {
          setDdpConfirmedCount(""); // リセット
        }
      } else if (name === "zoomUnavailableDays") {
        updatedArray = [...surveyAnswers.zoomUnavailable.days];
        if (checked) {
          updatedArray.push(value);
        } else {
          updatedArray = updatedArray.filter((item) => item !== value);
        }
        setSurveyAnswers((prev) => ({
          ...prev,
          zoomUnavailable: {
            ...prev.zoomUnavailable,
            days: updatedArray,
          },
        }));
      } else if (name === "zoomUnavailableTimes") {
        updatedArray = [...surveyAnswers.zoomUnavailable.times];
        if (checked) {
          updatedArray.push(value);
        } else {
          updatedArray = updatedArray.filter((item) => item !== value);
        }
        setSurveyAnswers((prev) => ({
          ...prev,
          zoomUnavailable: {
            ...prev.zoomUnavailable,
            times: updatedArray,
          },
        }));
      }
    } else if (name === "ddpAchieved") {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
        // ddpAchievedが"no"の場合、ddpWishをリセット
        ddpWish: value === "yes" ? prev.ddpWish : "",
      }));
    } else {
      setSurveyAnswers((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // DDP確定人数の更新処理
    if (name === "ddpConfirmedCount") {
      setDdpConfirmedCount(value);
    }
  };

  const handleSaveSurvey = async () => {
    if (!surveyAnswers || !user || !group) return;

    const surveyData = {
      createdAt: serverTimestamp(),
      surveyId,
      answers: { ...surveyAnswers, ddpConfirmedCount }, // DDP確定人数を追加
      userId: user.uid,
      groupId: id, // グループIDを追加
    };

    try {
      // `/users/userId/fab` コレクションに保存
      const userFabRef = doc(db, "users", user.uid, "fab", surveyId);
      await setDoc(userFabRef, surveyData);

      // `/fab` コレクションにも保存
      const fabRef = collection(db, "fab");
      await addDoc(fabRef, surveyData);

      console.log(
        "アンケートが`/users/userId/fab`と`/fab`コレクションに保存されました"
      );

      setSurveyDialogOpen(false); // ダイアログを閉じる
      setThankYouDialogOpen(true); // ありがとうダイアログを開く
    } catch (error) {
      console.error("アンケートの保存に失敗しました", error);
    }
  };

  const handleThankYouDialogClose = () => {
    setThankYouDialogOpen(false);
  };

  const handleCloseFab = () => {
    setFabVisible(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavigationChange}
        style={{
          position: "fixed",
          top: topOffset,
          backgroundColor: "#ffffff",
          zIndex: 1000,
          maxWidth: "1152px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          height: "56px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Tooltip title="仲間" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 0 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="仲間"
            icon={<GroupIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="DDP" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 1 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="DDP"
            icon={<FavoriteIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="Chat" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 2 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="Chat"
            icon={<ChatIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>

        <Tooltip title="設定" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 3 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            label="設定"
            icon={<SettingsIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        <Tooltip title="ソフィーからのミッション" arrow>
          <BottomNavigationAction
            style={{
              minWidth: "auto",
              flex: "1",
              color: value === 4 ? "#1976d2" : "#757575",
              transition: "color 0.3s",
            }}
            onClick={handleMissionClick}
            icon={<StarIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>

        {user?.isAdmin && (
          <Tooltip title="10/20" arrow>
            <BottomNavigationAction
              style={{
                minWidth: "auto",
                flex: "1",
                color: value === 5 ? "#1976d2" : "#757575",
                transition: "color 0.3s",
              }}
              label="10/20"
              icon={<MovieIcon style={{ fontSize: 30 }} />}
            />
          </Tooltip>
        )}
      </BottomNavigation>

      {/* Fab (Floating Action Button) for Survey */}
      <Fab
        id="survey-fab"
        color="primary"
        aria-label="アンケート"
        onClick={handleSurveyClick}
        style={{
          position: "fixed",
          bottom: "80px", // BottomNavigationの上に配置する
          right: "16px",
          zIndex: 1100,
          display: isFabVisible ? "block" : "none",
        }}
      >
        <SurveyIcon />
      </Fab>

      <div style={{ flex: 1, marginTop: topOffset }}>
        {value === 0 && <GroupMembers group={group} />}
        {value === 1 && <DDPSetting />}
        {value === 2 && <GroupChatEx groupId={id} />}
        {value === 3 && <Settings />}
        {value === 5 && user?.isAdmin && <Webinar groupId={id} />}{" "}
        {/* ここでisAdminチェックを追加 */}
      </div>

      <Drawer
        anchor="right"
        open={isMissionDrawerOpen}
        onClose={handleDrawerClose}
      >
        <SophieMission onClose={handleDrawerClose} />
      </Drawer>

      {/* アンケート */}
      <Dialog
        open={isSurveyDialogOpen}
        onClose={handleSurveyClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>アンケート</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            以下の質問にお答えください。
          </Typography>

          {/* チャット有無 */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                チャットは利用していますか？
              </Typography>
              <RadioGroup
                aria-label="chat-presence"
                name="chatPresence"
                value={surveyAnswers.chatPresence}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="はい"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="いいえ"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* 活動人数 */}
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="activity-number-label">
                実際の活動人数は？
              </InputLabel>
              <Select
                labelId="activity-number-label"
                name="activityNumber"
                value={surveyAnswers.activityNumber}
                onChange={handleSurveyAnswerChange}
                label="実際の活動人数は？"
              >
                {Array.from({ length: 10 }, (_, i) => (
                  <MenuItem key={i + 1} value={10 - i}>
                    {10 - i}人
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* グループで取り組めたミッションを教えてください（複数選択） */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                グループで取り組めたミッションを教えてください。
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("onlineMeeting")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="onlineMeeting"
                    />
                  }
                  label="オンライン（Zoomなど）で顔合わせをした"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes(
                        "inPersonMeeting"
                      )}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="inPersonMeeting"
                    />
                  }
                  label="実際に会ったことがある"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes(
                        "discussionTheme"
                      )}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="discussionTheme"
                    />
                  }
                  label="お互いを知ることのできそうなテーマを考えて話すことができた"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("jointActivity")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="jointActivity"
                    />
                  }
                  label="今参加しているメンバーで共通して取り組むことを実践した"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surveyAnswers.missions.includes("ddpPoints")}
                      onChange={handleSurveyAnswerChange}
                      name="missions"
                      value="ddpPoints"
                    />
                  }
                  label="DDPの作成のポイントについてグループメンバー内で共通認識が持てている"
                />
              </FormGroup>
            </FormControl>
          </Box>

          {/* DDPを深堀し、冬至までのDDPを確定した人数を教えてください */}
          {/* DDPを深堀し、冬至までのDDPを確定した人数を教えてください */}
          {surveyAnswers.missions.includes("ddpPoints") && (
            <Box sx={{ mt: 2 }}>
              {/* 問いを表示させる部分 */}
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                共通認識を元に、メンバーで深堀しDDPを確定した人数を教えてください。
              </Typography>

              <FormControl fullWidth>
                <InputLabel id="ddp-confirmed-count-label">
                  DDPを確定した人数
                </InputLabel>
                <Select
                  labelId="ddp-confirmed-count-label"
                  name="ddpConfirmedCount"
                  value={ddpConfirmedCount}
                  onChange={handleSurveyAnswerChange}
                  label="DDP確定人数"
                >
                  {Array.from({ length: 11 }, (_, i) => (
                    <MenuItem key={i} value={i}>
                      {i}人
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {/* 全体zoom会の開催時間帯、都合が悪いものを複数選択 */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                全体zoom会の開催時間帯で都合が悪いものを選択してください。
              </Typography>
              <Typography variant="subtitle2">
                ※全体Zoom会の2回目は、皆様からいただいた情報をもとに開催させていただく予定です。ご希望に添えない場合もございますが、何卒ご理解賜りますようお願い申し上げます。
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                <FormGroup>
                  {/* 曜日と時間帯の組み合わせ */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "平日-午前"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="平日-午前"
                      />
                    }
                    label="平日 - 午前"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "平日-午後"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="平日-午後"
                      />
                    }
                    label="平日 - 午後"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "平日-夜"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="平日-夜"
                      />
                    }
                    label="平日 - 夜"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "土曜日-午前"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="土曜日-午前"
                      />
                    }
                    label="土曜日 - 午前"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "土曜日-午後"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="土曜日-午後"
                      />
                    }
                    label="土曜日 - 午後"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "土曜日-夜"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="土曜日-夜"
                      />
                    }
                    label="土曜日 - 夜"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "日曜日-午前"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="日曜日-午前"
                      />
                    }
                    label="日曜日 - 午前"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "日曜日-午後"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="日曜日-午後"
                      />
                    }
                    label="日曜日 - 午後"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyAnswers.zoomUnavailable.times.includes(
                          "日曜日-夜"
                        )}
                        onChange={handleSurveyAnswerChange}
                        name="zoomUnavailableTimes"
                        value="日曜日-夜"
                      />
                    }
                    label="日曜日 - 夜"
                  />
                </FormGroup>
              </Box>
            </FormControl>
          </Box>
          {/* みつろうさんに質問をしたいこと（自由記述） */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">
              10/20の全体Zoom会で、みつろうさんに質問をしたいこと
            </Typography>

            <FormControl fullWidth>
              <TextField
                label="10/20の全体Zoom会で、みつろうさんに質問をしたいこと"
                name="questionsToMitsurou"
                value={surveyAnswers.questionsToMitsurou}
                onChange={handleSurveyAnswerChange}
                multiline
                rows={4}
              />
            </FormControl>
          </Box>

          {/* 既にDDPが叶った方は願いの内容を教えてください（yes/no） */}
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="subtitle2">
                すでにDDPは叶いましたか？「はい」の方は内容を教えて下さい。
              </Typography>
              <RadioGroup
                aria-label="ddp-achieved"
                name="ddpAchieved"
                value={surveyAnswers.ddpAchieved}
                onChange={handleSurveyAnswerChange}
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="はい"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="いいえ"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* yesの方のみ自由記述 */}
          {surveyAnswers.ddpAchieved === "yes" && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <TextField
                  label="DDPの内容"
                  name="ddpWish"
                  value={surveyAnswers.ddpWish}
                  onChange={handleSurveyAnswerChange}
                  multiline
                  rows={4}
                />
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSurveyClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleSaveSurvey}
            color="primary"
            variant="contained"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* ありがとうダイアログ */}
      <Dialog
        open={isThankYouDialogOpen}
        onClose={handleThankYouDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>ありがとうございます</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            アンケートにご協力いただき、ありがとうございます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThankYouDialogClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Group;
