import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Snackbar,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { db } from "../api/firebase";
import { collection, addDoc, getDocs, doc, getDoc, query, where, serverTimestamp, updateDoc, arrayRemove, orderBy, limit } from "firebase/firestore";
import { useStreamChatClient } from "../context/StreamChatProvider";

// 都道府県のリスト
const prefectures = [
  "無法松", "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県",
  "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県",
  "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県",
  "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県",
  "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県",
  "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県", "海外（アメリカ）", "海外（その他）",
];

// 管理者ユーザーのIDリスト
const adminUserIds = [
  "wpo4xvlL12QYGeHfmkGHCjrh9aW2",
  "QZvw3xeyFGbaUUL5etl8R8Sq59j1",
  "sLMZFtZx5RbPj2CPcW90fwex2gu1",
  "HqCUCJv5yHV7XcbbhJuwbRJYm1V2",
  "Gxf2Oq0WnhehnoMIPz1H9k5NURk1"
];

const useUsers = (selectedPrefecture) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      let usersList = [];
      if (selectedPrefecture === "管理者") {
        const adminUserPromises = adminUserIds.map(async (userId) => {
          const userRef = doc(db, "users", userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            const ddpRef = collection(db, `users/${userId}/ddps`);
            const ddpSnapshot = await getDocs(ddpRef);
            const ddpData = ddpSnapshot.docs[0]?.data()?.ddp || '';
            return { id: userId, ...userData, ddp: ddpData };
          }
          return null;
        });
        usersList = await Promise.all(adminUserPromises);
        usersList = usersList.filter(user => user !== null);
      } else {
        let usersQuery;
        if (selectedPrefecture === "無法松") {
            console.log("無法松 selected")
          usersQuery = query(
            collection(db, "users"),
            where("purpose", "!=", "音源のダウンロードのみ"),
            where("isVerified", "==", false),
            // where("participatingGroup", "==", ""),
            where("isStop", "==", false),
            // limit(20)
          );
        } else if (selectedPrefecture) {
          usersQuery = query(
            collection(db, "users"),
            where("purpose", "==", "音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"),
            where("isVerified", "==", true),
            where("prefecture", "==", selectedPrefecture),
            where("isStop", "==", false)
          );
        } else {
          usersQuery = query(
            collection(db, "users"),
            where("purpose", "==", "音源のダウンロードとマスターマインドグループで自分と他者のDDPを叶える"),
            where("isVerified", "==", true),
            where("isStop", "==", false)
          );
        }

        const querySnapshot = await getDocs(usersQuery);
        const userPromises = querySnapshot.docs.map(async (userDoc) => {
          const userData = userDoc.data();
          const ddpRef = collection(db, `users/${userDoc.id}/ddps`);
          const ddpSnapshot = await getDocs(ddpRef);
          const ddpData = ddpSnapshot.docs[0]?.data()?.ddp || '';
          return { id: userDoc.id, ...userData, ddp: ddpData };
        });
        usersList = await Promise.all(userPromises);
      }

      setUsers(usersList);
    } catch (error) {
      console.error("Error fetching users: ", error);
      setError("ユーザーの取得に失敗しました。");
    } finally {
      setLoading(false);
    }
  }, [selectedPrefecture]);

  useEffect(() => {
    if (selectedPrefecture) {
      fetchUsers();
    }
  }, [selectedPrefecture, fetchUsers]);

  return { users, loading, error, fetchUsers };
};

// グループの取得フック
const useGroups = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchGroups = useCallback(async () => {
    setLoading(true);
    setError("");
    try {

      const groupsQuery = query(
        collection(db, "groups"),
        where("name", ">=", "神"),
        where("name", "<", "神" + "\uf8ff"),
        orderBy("name", "asc")
      );


      // const groupsQuery = query(
      //   collection(db, "groups"),
      //   orderBy("createdAt", "desc"),
      //   limit(5)
      // );

      const groupSnapshot = await getDocs(groupsQuery);
      const groupsList = groupSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      groupsList.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      setGroups(groupsList);
    } catch (error) {
      console.error("Error fetching groups: ", error);
      setError("グループの取得に失敗しました。");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return { groups, loading, error, fetchGroups, setGroups };
};

// ユーザーリストコンポーネント
const UserList = ({ users, onToggleUser, getUserBackgroundColor, getUserBorderStyle }) => (
  <List>
    {users.map((user, index) => (
      <Accordion key={user.id} sx={{ marginBottom: '4px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          sx={{
            border: getUserBorderStyle(user.ddp),
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            backgroundColor: getUserBackgroundColor(user),
            minHeight: '40px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Checkbox
            checked={false}
            onClick={(event) => {
              event.stopPropagation();
              onToggleUser(user);
            }}
            sx={{ padding: '4px' }}
          />
          <Avatar src={user.avatar} sx={{ marginRight: '8px', width: 24, height: 24 }} />
          <ListItemText
            primary={`${index + 1}. ${user.name}`}
            secondary={`${user.prefecture},${user.registrationName}`}
            primaryTypographyProps={{ variant: 'body2' }}
            secondaryTypographyProps={{ variant: 'body2' }}
            sx={{ margin: 0 }}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '4px 8px' }}>
          <Typography variant="body2">生年: {user.birthyear}</Typography>
          <Typography variant="body2">DDP: {user.ddp || '未設定'}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </List>
);

// グループリストコンポーネント
const GroupList = ({ groups, currentGroup, onGroupClick, onEditGroupName, onRemoveUser, onSaveGroup, isGroupModified, getUserBackgroundColor }) => (
  <List>
    {groups.map((group, index) => (
      <Accordion
        key={group.id}
        onClick={() => onGroupClick(group)}
        sx={{
          backgroundColor: currentGroup?.id === group.id ? '#f0f0f0' : (group.members.length === 8 ? '#ffd001' : '#fff'),
          marginBottom: '4px',
          minHeight: '40px',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
            {group.id} - {group.name || `グループ ${index + 1}`} ({group.members.length}名)
          </Typography>
          <IconButton edge="end" onClick={(event) => {
            event.stopPropagation();
            onEditGroupName(group);
          }}>
            <EditIcon />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '4px 8px' }}>
          <List>
            {group.members.map((member, idx) => (
              <ListItem
                key={member.id}
                sx={{
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  mb: '4px',
                  backgroundColor: getUserBackgroundColor(member),
                  padding: '4px',
                  minHeight: '40px',
                }}
              >
                <Avatar src={member.avatar} sx={{ marginRight: '8px', width: 24, height: 24 }} />
                
                <ListItemText
                  primary={`${idx + 1}. ${member.name}`}
                  secondary={`${member.prefecture}, ${member.sextype}, ${member.municipality},${member.email} ` }
                  primaryTypographyProps={{ variant: 'body2' }}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
                <ListItemText
                  primary={`生年: ${member.birthyear}`}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => onRemoveUser(member.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => onSaveGroup(group)}
            sx={{ marginTop: '8px' }}
            disabled={!isGroupModified[group.id]}
          >
            この状態を保存する
          </Button>
        </AccordionDetails>
      </Accordion>
    ))}
  </List>
);

function GroupManagementEx() {
  const chatClient = useStreamChatClient();
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [currentGroup, setCurrentGroup] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [isGroupModified, setIsGroupModified] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { users, loading: usersLoading, error: usersError, fetchUsers } = useUsers(selectedPrefecture);
  const { groups, loading: groupsLoading, error: groupsError, fetchGroups, setGroups } = useGroups();

  const handleToggleUser = (user) => {
    if (currentGroup) {
      const updatedGroup = { ...currentGroup };
      const userIndex = updatedGroup.members.findIndex(member => member.id === user.id);
      if (userIndex > -1) {
        updatedGroup.members = updatedGroup.members.filter(member => member.id !== user.id);
      } else {
        updatedGroup.members.push(user);
      }
      setCurrentGroup(updatedGroup);
      setIsGroupModified({ ...isGroupModified, [currentGroup.id]: true });
    } else {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.includes(user)
          ? prevSelectedUsers.filter((u) => u !== user)
          : [...prevSelectedUsers, user]
      );
    }
  };

  const handleRemoveUserFromGroup = (userId) => {
    setUserToRemove(userId);
    setOpenDialog(true);
  };

  const confirmRemoveUser = async () => {
    if (currentGroup) {
      const updatedGroup = { ...currentGroup };
      const removedUser = updatedGroup.members.find(member => member.id === userToRemove);
      updatedGroup.members = updatedGroup.members.filter(member => member.id !== userToRemove);
      setCurrentGroup(updatedGroup);
      setIsGroupModified({ ...isGroupModified, [currentGroup.id]: true });

      const userDocRef = doc(db, "users", userToRemove);
      await updateDoc(userDocRef, { participatingGroup: "" });

      const groupRef = doc(db, "groups", currentGroup.id);
      await updateDoc(groupRef, { members: arrayRemove(removedUser) });

      if (chatClient) {
        const channel = chatClient.channel('messaging', currentGroup.id);
        await channel.removeMembers([userToRemove]);
      }

      fetchUsers();
    }
    setOpenDialog(false);
    setUserToRemove(null);
  };

  const cancelRemoveUser = () => {
    setOpenDialog(false);
    setUserToRemove(null);
  };

  const saveGroupState = async (group) => {
    try {
      const groupRef = doc(db, "groups", group.id);
      await updateDoc(groupRef, { members: group.members });

      if (chatClient) {
        const channel = chatClient.channel('messaging', group.id);
        const channelState = await channel.query();

        const existingMembers = channelState.members.map(member => member.user_id);
        const newMembers = group.members.map(member => member.id);

        const membersToAdd = newMembers.filter(memberId => !existingMembers.includes(memberId));
        const membersToRemove = existingMembers.filter(memberId => !newMembers.includes(memberId) && !adminUserIds.includes(memberId));

        if (membersToAdd.length > 0) {
          await channel.addMembers(membersToAdd);
        }

        if (membersToRemove.length > 0) {
          await channel.removeMembers(membersToRemove);
        }

        for (const member of group.members) {
          const userDocRef = doc(db, "users", member.id);
          await updateDoc(userDocRef, { participatingGroup: group.id });
        }
      }

      setSnackbarMessage("グループの状態が保存されました。");
      setOpenSnackbar(true);
      setIsGroupModified({ ...isGroupModified, [group.id]: false });

      fetchUsers();
    } catch (error) {
      console.error("Error saving group state: ", error);
      setSnackbarMessage("グループの状態の保存に失敗しました。");
      setOpenSnackbar(true);
    }
  };

  const handleEditGroupName = (group) => {
    setCurrentGroup(group);
    setNewGroupName(group.name);
    setOpenEditDialog(true);
  };

  const confirmEditGroupName = async () => {
    if (currentGroup) {
      try {
        const groupRef = doc(db, "groups", currentGroup.id);
        await updateDoc(groupRef, { name: newGroupName });
        const updatedGroup = { ...currentGroup, name: newGroupName };
        setGroups(groups.map(group => group.id === updatedGroup.id ? updatedGroup : group));
        setSnackbarMessage("グループ名が更新されました。");
        setOpenSnackbar(true);
        setIsGroupModified({ ...isGroupModified, [currentGroup.id]: true });
      } catch (error) {
        console.error("Error updating group name: ", error);
        setSnackbarMessage("グループ名の更新に失敗しました。");
        setOpenSnackbar(true);
      }
    }
    setOpenEditDialog(false);
  };

  const cancelEditGroupName = () => {
    setOpenEditDialog(false);
    setNewGroupName("");
  };

  const getUserBackgroundColor = (user) => {
    if (user.participatingGroup) {
      return "#e0e0e0";
    }
    switch (user.sextype) {
      case "男性":
        return "#d0e1ff";
      case "女性":
        return "#ffd0e1";
      default:
        return "#fff";
    }
  };

  const getUserBorderStyle = (ddp) => {
    return ddp ? '2px solid #000' : '1px solid #ddd';
  };

  const handleCreateGroup = async () => {
    try {
      const groupRef = await addDoc(collection(db, "groups"), {
        createdAt: serverTimestamp(),
        members: selectedUsers,
      });
      const newGroupId = groupRef.id;

      const channel = chatClient.channel('messaging', newGroupId, {
        name: `グループ ${newGroupId}`,
        members: [...selectedUsers.map(user => user.id), ...adminUserIds],
        created_by: { id: adminUserIds[0] }
      });
      await channel.create();
      await channel.addMembers(adminUserIds, { role: 'owner' });

      for (const user of selectedUsers) {
        const userDocRef = doc(db, "users", user.id);
        await updateDoc(userDocRef, { participatingGroup: newGroupId });
      }

      for (const adminId of adminUserIds) {
        const adminDocRef = doc(db, "users", adminId);
        await updateDoc(adminDocRef, { participatingGroup: newGroupId });
      }

      const newGroup = { id: newGroupId, createdAt: { seconds: Math.floor(Date.now() / 1000) }, members: selectedUsers };
      setGroups([newGroup, ...groups]);
      setSelectedUsers([]);
      setSnackbarMessage("新しいグループを作成しました。");
      setOpenSnackbar(true);

      fetchUsers();
    } catch (error) {
      console.error("Error creating group: ", error);
      setSnackbarMessage("グループの作成に失敗しました。");
      setOpenSnackbar(true);
    }
  };

  const handleGroupClick = (group) => {
    setCurrentGroup(group);
    setIsGroupModified({ ...isGroupModified, [group.id]: false });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>グループ管理</Typography>
      <Grid container>
        <Grid item xs={5} sx={{ height: '80vh', overflowY: 'scroll', scrollbarWidth: 'thin' }}>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
            <TextField
              select
              label="都道府県を選択"
              value={selectedPrefecture}
              onChange={(e) => setSelectedPrefecture(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="">全て</MenuItem>
              {prefectures.map((pref) => (
                <MenuItem key={pref} value={pref}>
                  {pref}
                </MenuItem>
              ))}
            </TextField>
            {selectedPrefecture && (
              <Typography variant="body1" gutterBottom>
                {users.length} 件のユーザーが見つかりました
              </Typography>
            )}
          </Box>
          {usersLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : users.length > 0 ? (
            <UserList
              users={users}
              onToggleUser={handleToggleUser}
              getUserBackgroundColor={getUserBackgroundColor}
              getUserBorderStyle={getUserBorderStyle}
            />
          ) : (
            selectedPrefecture && (
              <Typography variant="body1" gutterBottom>
                該当するユーザーは見つかりませんでした
              </Typography>
            )
          )}
        </Grid>
        <Grid item xs={7} sx={{ pl: "15px" }}>
          <Typography variant="h6" gutterBottom>グループ（{groups.length}）</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGroup}
            size="small"
            sx={{ marginBottom: '16px' }}
          >
            グループを作成する
          </Button>
          <GroupList
            groups={groups}
            currentGroup={currentGroup}
            onGroupClick={handleGroupClick}
            onEditGroupName={handleEditGroupName}
            onRemoveUser={handleRemoveUserFromGroup}
            onSaveGroup={saveGroupState}
            isGroupModified={isGroupModified}
            getUserBackgroundColor={getUserBackgroundColor}
          />
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={cancelRemoveUser}>
        <DialogTitle>ユーザーを削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当にこのユーザーをグループから削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRemoveUser} color="primary">
            いいえ
          </Button>
          <Button onClick={confirmRemoveUser} color="primary" autoFocus>
            はい
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={cancelEditGroupName}>
        <DialogTitle>グループ名を編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="新しいグループ名"
            type="text"
            fullWidth
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEditGroupName} color="primary">
            キャンセル
          </Button>
          <Button onClick={confirmEditGroupName} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
}

export default GroupManagementEx;


